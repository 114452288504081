/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'vHXAA25gbLoKGryk5ABPy1.png': { uri: '/images/vHXAA25gbLoKGryk5ABPy1.png' },
'xdcP5uKRs6VuQvZidqSmDk.png': { uri: '/images/xdcP5uKRs6VuQvZidqSmDk.png' },
'6ZfPZrgRdxpsT1Cng93Hrw.png': { uri: '/images/6ZfPZrgRdxpsT1Cng93Hrw.png' },
'wtz7WBBeUEb6RPH52qyjWk.png': { uri: '/images/wtz7WBBeUEb6RPH52qyjWk.png' },
'ouowW5RuT1dVDM9FRpbnKj.png': { uri: '/images/ouowW5RuT1dVDM9FRpbnKj.png' },
'7TBt7HxT7BjwYve15GHdJh.png': { uri: '/images/7TBt7HxT7BjwYve15GHdJh.png' },
'v14njD9rP1MyMDhWKmbPjN.png': { uri: '/images/v14njD9rP1MyMDhWKmbPjN.png' },
'wXoguTBT1DZnpSRnGFnGEv.png': { uri: '/images/wXoguTBT1DZnpSRnGFnGEv.png' },
'8KBFCYUFC438PUpxWb32A9.png': { uri: '/images/8KBFCYUFC438PUpxWb32A9.png' },
'1TGbkus5e1VvzRcroL2ecY.png': { uri: '/images/1TGbkus5e1VvzRcroL2ecY.png' },
'px4DAySQ74vrxLRXfCU5e9.png': { uri: '/images/px4DAySQ74vrxLRXfCU5e9.png' },
'3XrwogPrWd9ZD4swD7PA8n.png': { uri: '/images/3XrwogPrWd9ZD4swD7PA8n.png' },
'2HJinzfJ5grFysKSGqcV6w.png': { uri: '/images/2HJinzfJ5grFysKSGqcV6w.png' },
's5wFLNFndBrVDYJSzDMViM.png': { uri: '/images/s5wFLNFndBrVDYJSzDMViM.png' },
'8cpAD8wNUMvqi3sFYcBLtc.png': { uri: '/images/8cpAD8wNUMvqi3sFYcBLtc.png' },
'2voV6zkDGozffjmoAUJ7MK.png': { uri: '/images/2voV6zkDGozffjmoAUJ7MK.png' },
'oGKUTjjc7u9y6njJxcVCGh.webp': { uri: '/images/oGKUTjjc7u9y6njJxcVCGh.webp' },
'52hgGgUVXMgwquRbNf83LC.png': { uri: '/images/52hgGgUVXMgwquRbNf83LC.png' },
'7Jsr92wTQMve3kJus4Xe3D.png': { uri: '/images/7Jsr92wTQMve3kJus4Xe3D.png' },
'7u3GZgAskAo9QaTCjPTSkW.png': { uri: '/images/7u3GZgAskAo9QaTCjPTSkW.png' },
'8UTnMS17tgqischGPiuC7z.png': { uri: '/images/8UTnMS17tgqischGPiuC7z.png' },
'eMW7LXouYzkPDFWxymVJiZ.png': { uri: '/images/eMW7LXouYzkPDFWxymVJiZ.png' },
'57XbYgEZjdVAnaKGEEYeaB.png': { uri: '/images/57XbYgEZjdVAnaKGEEYeaB.png' },
'cF76gcjroxXP2nfg8EwLVi.png': { uri: '/images/cF76gcjroxXP2nfg8EwLVi.png' },
'ut5aCvdFG3V14UrCTL1t78.png': { uri: '/images/ut5aCvdFG3V14UrCTL1t78.png' },
'nbybV4jkSDJJGsU5vuezf4.png': { uri: '/images/nbybV4jkSDJJGsU5vuezf4.png' },
'cmiNuH93Jo1soVyF5rx7qA.png': { uri: '/images/cmiNuH93Jo1soVyF5rx7qA.png' },
'tnG7xYcAcPQwaWGT55fUBi.png': { uri: '/images/tnG7xYcAcPQwaWGT55fUBi.png' },
'uGX52hgBeXtPAYBHtfyje4.png': { uri: '/images/uGX52hgBeXtPAYBHtfyje4.png' },
'vyn38fiz882HfgdZP8FL5y.png': { uri: '/images/vyn38fiz882HfgdZP8FL5y.png' },
'wRBZHxUZNhmPfDGeZYRXBb.png': { uri: '/images/wRBZHxUZNhmPfDGeZYRXBb.png' },
'iA83LvM9QaTETaWxQyPCcv.png': { uri: '/images/iA83LvM9QaTETaWxQyPCcv.png' },
'jRzw28Ue91soLjfxW8VagU.png': { uri: '/images/jRzw28Ue91soLjfxW8VagU.png' },
'3X7rUdktTHUCLZGWsTUGnT.png': { uri: '/images/3X7rUdktTHUCLZGWsTUGnT.png' },
'aokt48oAAviTmeH4XuFAMx.png': { uri: '/images/aokt48oAAviTmeH4XuFAMx.png' },
'no3sUaSGGVg842dQ4ApTP7.png': { uri: '/images/no3sUaSGGVg842dQ4ApTP7.png' },
'99zqskGvJYj9xbrY56RX8Y.png': { uri: '/images/99zqskGvJYj9xbrY56RX8Y.png' },
'kWcRjUVEL9UFo1tM1FopvQ.png': { uri: '/images/kWcRjUVEL9UFo1tM1FopvQ.png' }
}

export default imageStaticSourcesByFileName
